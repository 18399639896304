.page-project {
  header {
    border-bottom: 1px solid #eee; }
  .main-content {
    .main-content-media {
      padding: 20px;
      img {
        max-width: 100%;
        &:not(:first-child) {
          margin-top: 20px; } } }
    .main-content-info {
      padding: 20px;
      h1 {
        margin-top: 0;
        line-height: 1;
        @media screen and (max-width: $small) {
          margin-top: 30px; } }
      p {
        font-family: 'Playfair Display', serif;
        font-size: 15px;
        line-height: 2;
        text-align: justify; } } } }

