@import "variables";
@import "grid";
@import "projects";
@import "pages/page-home";
@import "pages/page-project";

* {
  box-sizing: border-box; }

html, body {
  margin: 0;
  padding: 0; }

body {
  font-family: 'Montserrat', sans-serif; }

a {
  text-decoration: none;
  color: $dark;
  &:hover {
    color: $accent; } }

.row {
  &.grey-row {
    background: #f7f6f4; }
  &.accent-row {
    background: #fff8f8; }
  &.text-row {
    h2 {
      margin: 0;
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 2px; }
    p {
      font-family: 'Playfair Display', serif;
      font-size: 18px;
      max-width: 800px;
      line-height: 2;
      margin: 0 auto; }
    h2+p {
      margin-top: 20px; }
    p+h2 {
      margin-top: 40px; }
    &.indent-left-row.text-row .row-inside {
      padding-left: 30px; }
    &.expo-row {
      p {
        max-width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0; } } } } }


header.row .row-inside {
  padding: $small-padding 10px; }

header .logo {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
  p {
    font-weight: normal;
    font-size: 9px;
    margin: 3px 0 0;
    color: $medium; } }
