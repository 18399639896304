.slideshow {
  overflow: hidden;
  height: 500px;
  width: 100%;
  .slide {
    background-position: center 40%;
    background-size: cover;
    height: 500px; }
  .promotion-banner {
    background: #f55a5a;
    padding: 20px;

    p {
      margin: 0;
      color: white;
      text-align: center;
      line-height: 1.5;
      a {
        color: white;
        text-decoration: underline; } } } }
