// breakpoints
$large: 1160px;
$medium: 1024px;
$small: 768px;

// row paddings
$large-padding: 100px;
$medium-padding: 70px;
$small-padding: 35px;

.row {
  width: 100%;
  .row-inside {
    max-width: $large;
    padding: $medium-padding 10px;
    margin: 0 auto;
    font-size: 0;
    .col {
      display: inline-block;
      vertical-align: top;
      font-size: 13px;
      &.col-lg-12 {
        width: 100%; }
      &.col-lg-8 {
        width: 66.66%; }
      &.col-lg-6 {
        width: 50%; }
      &.col-lg-4 {
        width: 33.33%; }
      &.col-lg-3 {
        width: 33.33%; } } } }

.row {
  &.large-padding-row .row-inside {
    padding-top: $large-padding;
    padding-bottom: $large-padding; }
  &.centered {
    text-align: center; }
  &.no-padding-top-row .row-inside {
    padding-top: 0; }
  &.no-padding-bottom-row .row-inside {
    padding-bottom: 0; }
  &.half-padding-top-row .row-inside {
    padding-top: $small-padding; }
  &.half-padding-bottom-row .row-inside {
    padding-bottom: $small-padding; } }

@media screen and (max-width: $medium) {
  .row .row-inside .col {
    &.col-md-12 {
      width: 100%; }
    &.col-md-6 {
      width: 50%; }
    &.col-md-3 {
      width: 33.33%; } }
  .row.invert-row-md {
    .row-inside,
    .row-inside .col {
      transform: scale(1, -1); } } }

@media screen and (max-width: $small) {
  .row {
    &.large-padding-row .row-inside {
      padding: $large-padding / 2 20px; } }
  .row .row-inside .col {
    &[class*="col-"] {
      width: 100%; }
    &.col-sm-12 {
      width: 100%; }
    &.col-sm-6 {
      width: 50%; }
    &.col-sm-3 {
      width: 33.33%; } }
  .row.invert-row-sm {
    .row-inside,
    .row-inside .col {
      transform: scale(1, -1); } } }
