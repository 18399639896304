section.filter {
  margin-top: 40px; }

section.filter > span {
  margin-right: 15px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px; }

section.filter > a {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 15px;
  position: relative; }

section.filter > a.active:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 20px;
  height: 2px;
  border-bottom: 3px solid; }

.projects-row .project {
  display: none;
  width: 33%;
  padding: 20px;
  color: $dark;
  figure {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 220px;
    padding: 0;
    margin: 0; }
  h4 {
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 15px;
    margin: 20px 0 0;
    text-align: center; } }

.projects-row .project.active {
  display: inline-block; }
@media screen and (max-width: $medium) {
  .projects-row .project {
    width: 50%; } }

@media screen and (max-width: $small) {
  .projects-row .project {
    width: 100%; } }
